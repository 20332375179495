import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Header from "../layout/Header";
import Flashmessages from "../flashMessagesComp/flashMessages";
import Loader from "../common/GlobalLoader";
import { API_CONSTANTS } from "../../shared/constants/api.constants";

import {
  sendFlashMessage,
  clearcurrentFlashMessage,
} from "../../actions/flashMessage";

class GoogleRemitance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      uploading: false,
    };

    this.onFileChange = this.onFileChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.fileInputRef = React.createRef();
  }

  onFileChange(e) {
    const file = e.target.files[0];

    if (!file) return;

    if (file.size > 1048576) {
      alert("Error: File should be less than 1MB");
      return;
    }

    if (!file.name.endsWith(".csv")) {
      alert("Error: Only .csv files allowed");
      return;
    }

    this.setState({ selectedFile: file });
  }

  createAxiosInstance = (token) => {
    return axios.create({
      baseURL: API_CONSTANTS.API_BASE_URL_C,
      headers: {
        "Authorization": `Bearer ${token}`,
      },
    });
  };

  onSubmit(e) {
    e.preventDefault();

    const { selectedFile } = this.state;
    if (!selectedFile) {
      this.props.sendFlashMessage("Please upload a file before submitting!", "alert-danger");
      setTimeout(() => {
        this.props.clearcurrentFlashMessage();
      }, 2000);
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    this.setState({ uploading: true });

    const token = localStorage.getItem("jwtToken");
    const axiosInstance = this.createAxiosInstance(token);

    axiosInstance.post(
      `${API_CONSTANTS.COUNCIL.PROCESS_GOOGLE_REMITANCE}`,
      formData
      )
      .then((res) => {
        this.props.sendFlashMessage("File processed successfully!", "alert-success");
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
        this.setState({ selectedFile: null, uploading: false });
        this.fileInputRef.current.value = null;
      })
      .catch((err) => {
        console.error("Upload error:", err);
        this.props.sendFlashMessage("Something went wrong during upload!", "alert-danger");
        setTimeout(() => {
          this.props.clearcurrentFlashMessage();
        }, 2000);
        this.setState({ uploading: false });
        this.fileInputRef.current.value = null; 
      });
  }

  render() {
    const { selectedFile, uploading } = this.state;

    return (
      <>
        <section className="wrapper">
          <Header />
          <section>
            <Flashmessages />
            <div className="container-fluid">
              <div className="spacetb25">
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <form onSubmit={this.onSubmit} encType="multipart/form-data">
                      <div className="card card__custom">
                        <div className="card-header">
                          <h5 className="card-title">Google Remitance</h5>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group textbox__label">
                                <label>Select CSV File</label>
                                <input
                                  type="file"
                                  className="form-control"
                                  accept=".csv"
                                  onChange={this.onFileChange}
                                  ref={this.fileInputRef}
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 text-center">
                              <button type="submit" className="btn btn-secondary">
                                Submit
                              </button>
                            </div>
                          </div>
                          {selectedFile && (
                            <div className="text-center mt-3">
                              <small>Selected File: {selectedFile.name}</small>
                            </div>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Loader loadStatus={uploading} />
        </section>
      </>
    );
  }
}

GoogleRemitance.propTypes = {
  sendFlashMessage: PropTypes.func.isRequired,
  clearcurrentFlashMessage: PropTypes.func.isRequired,
};

export default connect(null, {
  sendFlashMessage,
  clearcurrentFlashMessage,
})(GoogleRemitance);